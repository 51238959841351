import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/sections/hero"
import { attachImageEdgesRecursive } from "../utils/functions"
import { Fade } from "react-reveal"
import { MailIcon, PencilIcon, PhoneIcon } from "@heroicons/react/solid"

const kontakt = {
	seo: {
		title: "Unsere Kontaktdaten",
		description:
			"Per Post, via E-Mail, telefonisch oder über das Kontaktformular - Unsere Kontaktdaten",
	},
	hero: {
		btnLink: "/termin/#termin",
		btnText: "Termin buchen",
		image: "kontakt_hero.jpg",
		h1: "Zu 100 % steht der Termin mit unserem Geschäftsführer über den Link Termin buchen!",
		checklist: [
			"Wir antworten innerhalb von 24 Stunden",
			"Senden Sie uns alternativ gerne eine E-Mail",
			"So oder so – wir freuen uns auf den Kontakt mit Ihnen",
		],
	},
}

const Kontakt = () => {
	return (
		<StaticQuery
			query={graphql`
				query {
					images: allFile {
						edges {
							node {
								relativePath
								childImageSharp {
									gatsbyImageData(placeholder: TRACED_SVG)
								}
							}
						}
					}
				}
			`}
			render={(data) => {
				attachImageEdgesRecursive(data.images.edges, kontakt)

				return (
					<Layout>
						<Seo
							title={kontakt.seo?.title}
							description={kontakt.seo?.description}
						/>
						<Hero data={kontakt.hero} />
						<div className="bg-blue-50 w-full">
							<div className="sectionwidth sectionpadding">
								<h2 className="h2 font-bold text-primary">
									Wir freuen uns auf Ihre Kontaktaufnahme!
								</h2>

								<div className="flex flex-col lg:flex-row justify-between mt-16 border-t border-gray-500">
									<div className="flex flex-col items-center justify-start">
										<Fade left>
											<div className="h-16 w-16 mb-4 flex justify-center items-center bg-primary text-white font-bold rounded-full mt-8 lg:-mt-8">
												<PhoneIcon className="h-8 w-8" />
											</div>

											<h3 className="h3 text-primary font-bold mb-2">
												Telefon
											</h3>

											<div className="prose prose-xl max-w-full mb-4">
												<a href="tel:+493023595128" className="hover:underline">
													+49 (0)30-23595128
												</a>
											</div>
										</Fade>
									</div>

									<div className="flex flex-col items-center justify-start">
										<Fade left delay={200}>
											<div className="h-16 w-16 mb-4 flex justify-center items-center bg-primary text-white font-bold rounded-full mt-8 lg:-mt-8">
												<MailIcon className="h-8 w-8" />
											</div>

											<h3 className="h3 text-primary font-bold mb-2">E-Mail</h3>

											<div className="prose prose-xl max-w-full mb-4">
												<a
													href="mailto:hallo@internes-recruiting.de"
													className="hover:underline"
												>
													hallo@internes-recruiting.de
												</a>
											</div>
										</Fade>
									</div>

									<div className="flex flex-col items-center justify-start">
										<Fade left delay={400}>
											<div className="h-16 w-16 mb-4 flex justify-center items-center bg-primary text-white font-bold rounded-full mt-8 lg:-mt-8">
												<PencilIcon className="h-8 w-8" />
											</div>

											<h3 className="h3 text-primary font-bold mb-2">
												Termin buchen
											</h3>

											<div className="prose prose-xl max-w-full mb-4">
												D.I.E. STRATEGIE GmbH<br></br>
												Invalidenstr. 39<br></br>
												10115 Berlin
											</div>

											<div className="w-full flex justify-center">
												<Link className="btn btn-secondary" to="/termin">
													Termin buchen
												</Link>
											</div>
										</Fade>
									</div>
								</div>
							</div>
						</div>
					</Layout>
				)
			}}
		/>
	)
}

export default Kontakt
